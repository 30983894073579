import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Practical Well-being and Self-care (Physical, Mental, Emotional Health)`}</h1>
    <h2>{`2022-05-11 4:30pm`}</h2>
    <p><em parentName="p">{`Duration: 2 hours`}</em></p>
    {
      /*
      - [Add to Google Calendar]()
      - [Add to Microsoft Calendar]()
      */
    }
    <p>{`This session will explore practical well-being and self-care across physical, mental, and emotional dimensions.`}</p>
    <p>{`We will:`}</p>
    <ul>
      <li parentName="ul">{`Investigate the reasons and motivations behind self-care`}</li>
      <li parentName="ul">{`Cover the components of evidence-based well-being`}</li>
      <li parentName="ul">{`Review tips on developing a personal practice and building habits.`}</li>
    </ul>
    <p>{`Our ability to learn and generally work toward our goals is founded on and enabled by our overall well-being. Our overall well-being is also the basis of our perceptions, decision-making, and general quality of life. Let's explore cultivating a self care and well-being practice together.`}</p>
    <h2>{`Vivian Su`}</h2>
    <p>{`Hi I'm Vivian. I've worked in tech for over 10 years as a software engineer and as an engineering manager. I'm passionate about physical, mental, and emotional self-care and well-being. I believe self-care and well-being is an integral aspect of social justice, equity, and how we connect with our communities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      